var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('search-box',{attrs:{"label":"* Setor","popup-label":"Selecione um Setor","id":_vm.value.idSetor,"descricao":_vm.value.descricaoSetor,"hidden-clear":true,"value":_vm.setor,"loader-fn":_vm.loadeSetor,"item-key":"id","item-text":"descricao","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idSetor", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoSetor", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Setor encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('search-box',{attrs:{"label":"* Porte","popup-label":"Selecione um Porte","id":_vm.value.idPorte,"descricao":_vm.value.descricaoPorte,"hidden-clear":true,"value":_vm.porte,"loader-fn":_vm.loadePorte,"item-key":"id","item-text":"descricao","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idPorte", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoPorte", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Porte encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('search-box',{attrs:{"label":"* Ramo de Atução","popup-label":"Selecione um Ramo de Atução","id":_vm.value.idRamoAtuacao,"descricao":_vm.value.descricaoRamoAtuacao,"hidden-clear":true,"value":_vm.ramoAtuacao,"loader-fn":_vm.loadeRamoAtuacao,"item-key":"id","item-text":"descricao","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idRamoAtuacao", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoRamoAtuacao", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Ramo de Atução encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"* Razão Social","dense":"","rules":[
                      this.$validators.string.required,
                           function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                  ]},model:{value:(_vm.value.razaoSocial),callback:function ($$v) {_vm.$set(_vm.value, "razaoSocial", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.razaoSocial"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"* Nome Fantasia","dense":"","rules":[
                      this.$validators.string.required,
                           function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                  ]},model:{value:(_vm.value.nomeFantasia),callback:function ($$v) {_vm.$set(_vm.value, "nomeFantasia", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nomeFantasia"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"label":"* CNPJ","dense":"","placeholder":"99.999.999/9999-99","rules":[
                    this.$validators.string.required,
                    this.$validators.string.cnpj
                  ]},model:{value:(_vm.value.cnpj),callback:function ($$v) {_vm.$set(_vm.value, "cnpj", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cnpj"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"6","label":"* Descrição da Empresa","rules":[
          this.$validators.string.required,
          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 4000); } ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Contato")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Email","dense":"","rules":[
                    this.$validators.string.email,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 50); } ]},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"Telefone/Celular","dense":""},model:{value:(_vm.value.telefone),callback:function ($$v) {_vm.$set(_vm.value, "telefone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.telefone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Site/Rede Social","dense":""},model:{value:(_vm.value.siteRede),callback:function ($$v) {_vm.$set(_vm.value, "siteRede", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.siteRede"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Localização")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('########'),expression:"'########'"}],attrs:{"label":"* CEP","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 8); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 8); } ]},model:{value:(_vm.value.cep),callback:function ($$v) {_vm.$set(_vm.value, "cep", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cep"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Cidade","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ],"value":_vm.value.cidade}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('lookup-dominio',{attrs:{"label":"* Estado","hidden-clear":true,"value":_vm.value.estado,"type":_vm.lookups.estado,"rules":[
                        this.$validators.string.required
                    ]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Bairro","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.bairro),callback:function ($$v) {_vm.$set(_vm.value, "bairro", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.bairro"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Endereço","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.endereco),callback:function ($$v) {_vm.$set(_vm.value, "endereco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.endereco"}}):_c('v-text-field',{attrs:{"label":"* Endereço","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.endereco),callback:function ($$v) {_vm.$set(_vm.value, "endereco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.endereco"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"Complemento","rules":[
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.complemento),callback:function ($$v) {_vm.$set(_vm.value, "complemento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.complemento"}}):_c('v-text-field',{attrs:{"label":"Complemento","dense":"","rules":[
                      function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.complemento),callback:function ($$v) {_vm.$set(_vm.value, "complemento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.complemento"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }